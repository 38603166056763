<script setup lang="ts">
	import { useUserStore } from "@/store/userStore";
	import { useSiteStore } from "@/store/siteStore";
	import { useCommonCodeStore } from "@/store/commonCodeStore";
	import { UserAuthGroup } from "@/enums";
	import { useEquipStore } from "@/store/equipStore";
	import OwnerSiteListToggle from "@/client/components/OwnerSiteListToggle.vue";
	import { useEtcStore } from "@/store/etcStore";
	import { useMenuStore } from "@/store/menuStore";
	import { NativeAction } from "~~/src/enums";
	import CommonUtils from "~/utils/common-utils";
  import {UserType} from "@/enums";

	const siteStore = useSiteStore();
	const userStore = useUserStore();
	const equipStore = useEquipStore();
	const commonCodeStore = useCommonCodeStore();
	// await userStore.getUsers();
	const etcStore = useEtcStore();
	const menuStore = useMenuStore();

	const router = useRouter();
  const route = useRoute();

	const userId = userStore.loginUserInfo?.userSeq as number;
	const userType = computed(() => {
		return userStore.getUserType;
	});

	const sitelisttoggle = ref();
	const ownerSiteListToggle = ref(null);

	const siteToggleFunction = ref();

  const selectSiteInfo = computed(() => equipStore.selectSiteInfo);

	const selectEquipInfo = computed(() => equipStore.selectEquipInfo);

	const alarmCount = await etcStore.getAlarmCount();

	const selectSiteEquipNames = computed(() => {
		let name = "";

		if (userType.value === UserType.Manager || userType.value === UserType.Admin) {
			name = `(${commonCodeStore.getMapCode("opStatus", selectEquipInfo.value.equipStatus as string)?.codeName})/${
				commonCodeStore.getMapCode("engSource", equipStore.selectEquipInfo.engSource as string)?.codeName
			}/${equipStore.selectEquipInfo.siteName}`;
		} else {
			name = equipStore.selectSiteInfo.siteName;
		}

		return name;
	});

	// onMounted(() => {
	// 	siteToggleFunction.value = ownerSiteListToggle.value;
	// });

	function selectSite() {
		// let toggle = document.getElementsByClassName("ownerSiteToggle")[0] as HTMLElement;

		// toggle.style.display = "block";

		if (!ownerSiteListToggle.value._) {
			siteToggleFunction.value = ownerSiteListToggle.value;
		} else {
			siteToggleFunction.value = ownerSiteListToggle.value._.exposed;
		}

		// if (!siteToggleFunction) siteToggleFunction.value = ownerSiteListToggle.value;

		siteToggleFunction.value.siteToggle("btn-share");
	}



	const routerName = computed(() => {
		return commonCodeStore.getEngSourceName(equipStore.selectEquipInfo.engSource as string);
	});

	// function headerIconHaptic () {
	// 	const { $invokeNativeAction } = useNuxtApp();

	// 	try {
	// 		// @ts-ignore
	// 		$invokeNativeAction(NativeAction.HapticClick);
	// 	} catch {
	// 	}
	// }

	function goHome() {
		if (userType.value === UserType.Manager || userType.value === UserType.Admin) {
			menuStore.setSelectMainMenu("InstruInfo");
			router.replace(`/mobile/user/${userStore.loginUserInfo?.userSeq as number}/views/equipInfo/company/${routerName.value}/InstruInfo`);
		} else {
			menuStore.setSelectMainMenu("monitoring");
			router.replace(`/mobile/user/${userStore.loginUserInfo?.userSeq as number}/views/monitoring/${routerName.value}`);
		}
	}
</script>

<template>
	<!-- 상단 -->
	<div id="header" class="type-a">
		<div class="header-top">
			<h1>
				<NuxtLink @click="goHome"><img src="@/assets/images/logo.svg" alt="THE KIE" /></NuxtLink>
				<!-- <a href="./monitoring.html"></a> -->
			</h1>
			<div class="link">
				<NuxtLink v-if="userStore.getUserType === UserType.Manager || userStore.getUserType === UserType.Admin" :to="`/mobile/user/${userId}/equipMap`"></NuxtLink>
				<NuxtLink v-else :to="`/mobile/user/${userId}/views/status`"></NuxtLink>
				<!-- <a href="./ele-list.html" class="btn-view">발전사이트현황</a> -->
				<NuxtLink :to="`/mobile/user/${userId}/etc/alarm/all`" class="btn-alarm">알림페이지<i class="alarm" v-if="alarmCount > 0"></i></NuxtLink>
				<!-- <a href="./alarm.html" class="btn-alarm"></i></a> -->
				<NuxtLink :to="`/mobile/user/${userId}/index`" class="btn-my">마이페이지</NuxtLink>
				<!-- <a href="./mypage.html" class="btn-my">마이페이지</a> -->
			</div>
		</div>
		<NuxtLink :to="`/mobile/user/${userId}/equipList`" :custom="true" activeClass="on" v-slot="{ navigate }" v-if="userType === UserType.Manager || userType === UserType.Admin">
			<div class="site-select" @click="navigate">
				<a v-if="selectSiteEquipNames === null">선택된 설비가 없습니다.</a>
				<a v-else>
					<span class="area">{{ CommonUtils.setAddrString(selectEquipInfo.equipAddr as string) }}</span>
					{{ selectSiteEquipNames }}
				</a>
			</div>
		</NuxtLink>
		<!-- <NuxtLink :to="`/mobile/user/${userId}/siteList`" :custom="true" activeClass="on" v-slot="{ navigate }" v-else-if="userType === 'operator'">
			<div class="site-select" @click="navigate">
				<a v-if="selectSiteEquipNames === null">선택된 사이트가 없습니다.</a>
				<a v-else>
					<span class="area">{{ equipAddrSub(selectEquipInfo.equipAddr as string) }}</span>
					{{ `${selectEquipInfo.siteName}` }}
				</a>
			</div>
		</NuxtLink> -->
		<div class="site-select" @click="selectSite" v-else>
			<!-- <div class="site-select" v-else> -->
			<a v-if="selectSiteEquipNames === null">선택된 사이트가 없습니다.</a>
			<a v-else>
				<span class="area">{{ CommonUtils.setAddrString(selectSiteInfo.siteAddr as string) }}</span>
				<span style="padding: 5px 0px 0px 5px; line-height: 2">{{ `${selectSiteInfo.siteName}` }}</span>
			</a>
		</div>
	</div>
	<OwnerSiteListToggle ref="ownerSiteListToggle" v-if="userType !== UserType.Manager && userType !== UserType.Admin"></OwnerSiteListToggle>
	<!-- <header class="text-center position-fixed w-100 top-0 start-0 text-lg-start text-muted bg-primary">
      <div class="row m-0 justify-content-between px-2 py-2 align-items-center" >
        <div class="col-6">
          이미지2
        </div>
        <div class="col-4">
          <div class="row">
            <button class="col-4">1</button>
            <button class="col-4">1</button>``````````````````````````````````````````
            <button class="col-4">1</button>
          </div>
        </div>
      </div>
      <slot name="header" />
    </header> -->
</template>

<style lang="scss">
	.site-select {
		a {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
		}
	}
	#header {
		user-select: none;
	}
	.site-select {
		padding-left: 6px !important;
	}
	.area {
		margin-right: 0px !important;
	}
</style>
